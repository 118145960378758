import React from "react"
import * as styles from "./Breadcrumbs.module.scss"
import STRING_INDEX from "../../const/stringIndex"

export default function Breadcrumbs({ title, slug }) {
  const crumbs = slug
    .split("/")
    .filter((s, i) => !!s && i !== slug.split("/").length - 1)

  const handleClick = path => () => {
    if (window) {
      window.location.href = path
    }
  }

  return (
    <div className={styles.breadcrumbs}>
      <div
        className={`${styles.breadcrumbsItem} ${styles.breadcrumbsDoc}`}
        onClick={handleClick("/docs")}
      >
        Docs
      </div>
      <span>/</span>
      {crumbs.map(crumb => (
        <React.Fragment key={crumb}>
          <div className={styles.breadcrumbsItem}>
            {STRING_INDEX[crumb] || crumb}
          </div>
          <span>/</span>
        </React.Fragment>
      ))}
      <div className={styles.breadcrumbsItem}>{title}</div>
    </div>
  )
}
