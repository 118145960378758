// extracted by mini-css-extract-plugin
export var active = "Navigation-module--active--2dYCA";
export var hasChildren = "Navigation-module--hasChildren--3diWZ";
export var isExpanded = "Navigation-module--isExpanded--3oAmV";
export var isOpen = "Navigation-module--isOpen--3lEWf";
export var itemChildren = "Navigation-module--itemChildren--2jwEI";
export var level0 = "Navigation-module--level0--1bcbv";
export var navigation = "Navigation-module--navigation--3DEBm";
export var navigationItemChildren = "Navigation-module--navigationItemChildren--wxOHL";
export var navigationItemTitle = "Navigation-module--navigationItemTitle--8cbCz";
export var showIn = "Navigation-module--show-in--1I78Y";