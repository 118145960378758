// extracted by mini-css-extract-plugin
export var componentDoc = "Doc-module--componentDoc--1j_C1";
export var doc = "Doc-module--doc--1xmZV";
export var docContent = "Doc-module--docContent--2dZYA";
export var docContentBody = "Doc-module--docContentBody--2M8wL";
export var docMobileNavButton = "Doc-module--docMobileNavButton--gM5Ws";
export var docQuickLink = "Doc-module--docQuickLink--2wLYD";
export var docQuickLinkSubtitle = "Doc-module--docQuickLinkSubtitle--3Tt_2";
export var docQuickLinkTitle = "Doc-module--docQuickLinkTitle--wO3Xn";
export var docQuickLinks = "Doc-module--docQuickLinks--fued6";
export var next = "Doc-module--next--2lmRV";
export var showIn = "Doc-module--show-in--30KgT";