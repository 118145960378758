// extracted by mini-css-extract-plugin
export var active = "SupportFooter-module--active--1Yyah";
export var docSupport = "SupportFooter-module--docSupport--3zczy";
export var docSupportDiscord = "SupportFooter-module--docSupportDiscord--1vmFw";
export var docSupportFeedback = "SupportFooter-module--docSupportFeedback--LwCMY";
export var docSupportFeedbackOverlay = "SupportFooter-module--docSupportFeedbackOverlay--3zL2a";
export var docSupportFeedbackOverlayFail = "SupportFooter-module--docSupportFeedbackOverlayFail--PUCoK";
export var docSupportFeedbackOverlayFailInput = "SupportFooter-module--docSupportFeedbackOverlayFailInput--3igQr";
export var docSupportFeedbackOverlaySuccess = "SupportFooter-module--docSupportFeedbackOverlaySuccess--25lCX";
export var docSupportFeedbackVotes = "SupportFooter-module--docSupportFeedbackVotes--y-epr";
export var docSupportFeedbackVotesDown = "SupportFooter-module--docSupportFeedbackVotesDown--2Fivl";
export var docSupportFeedbackVotesUp = "SupportFooter-module--docSupportFeedbackVotesUp--1Q_Or";
export var showIn = "SupportFooter-module--show-in--7ZwlV";