import React from "react"
import * as styles from "./Navigation.module.scss"
import STRING_INDEX from "../../const/stringIndex"

export default function Navigation({ activeSlug, isOpen, items }) {
  const isOpenClassName = isOpen ? styles.isOpen : ""
  return (
    <div className={`${styles.navigation} ${isOpenClassName}`}>
      <Children items={items} activeSlug={activeSlug} />
    </div>
  )
}

function Children({ items = [], level = 0, activeSlug = "" }) {
  const initialItems = level < 2 ? new Array(items.length).fill(true) : {}
  const [expandIndex, setExpandIndex] = React.useState(initialItems)
  const [expandHeightIndex, setExpandHeightIndex] = React.useState({})
  const [refIndex, setRefIndex] = React.useState({})

  const handleClick = i => () => {
    const isNowExpanded = !expandIndex[i]
    setExpandIndex({ ...expandIndex, [i]: isNowExpanded })
    setExpandHeightIndex({
      ...expandHeightIndex,
      [i]: isNowExpanded ? refIndex[i].getBoundingClientRect().height + 8 : 0,
    })

    setTimeout(() => {
      if (!isNowExpanded) return
      setExpandHeightIndex({
        ...expandHeightIndex,
        [i]: "auto",
      })
    }, 150)
  }

  const handleRef = (i, active) => ref => {
    if (ref && !refIndex[i]) {
      setRefIndex({ ...refIndex, [i]: ref })
      if (active) {
        setExpandIndex({ ...expandIndex, [i]: true })
        ref.scrollIntoView({
          block: "center",
          inline: "nearest",
        })
      }
    }
  }

  if (!items) return null
  return items.map(
    ({ children, node, title: propTitle, slug: propSlug, nav }, i) => {
      const slug = propSlug || (node && node.frontmatter.slug)
      const title = propTitle || (node && node.frontmatter.title)
      const hasChildren = !!children
      const hasChildrenClassName = hasChildren ? styles.hasChildren : ""
      const expandedClassName = expandIndex[i] ? styles.isExpanded : ""
      const activeClassName = activeSlug === slug ? styles.active : ""
      const TitleComponent = hasChildren ? "div" : "a"
      const TitleComponentProps = hasChildren
        ? { onClick: level !== 0 ? handleClick(i) : null }
        : { href: `/docs${slug}` }

      const height =
        expandHeightIndex[i] === undefined
          ? expandIndex[i]
            ? "auto"
            : 0
          : expandHeightIndex[i]

      return (
        <React.Fragment key={nav || title || STRING_INDEX[slug] || slug}>
          <TitleComponent
            {...TitleComponentProps}
            className={`${styles.navigationItemTitle} ${
              styles[`level${level}`]
            } ${hasChildrenClassName} ${expandedClassName} ${activeClassName}`}
          >
            {nav || title || STRING_INDEX[slug] || slug}
          </TitleComponent>
          {hasChildren && (
            <div
              style={{
                overflow: "hidden",
                transition: "0.15s ease-out",
                height,
                marginBottom: height === 0 ? 0 : undefined,
              }}
            >
              <div
                ref={handleRef(i, activeSlug.split("/").includes(slug))}
                className={styles.navigationItemChildren}
              >
                <Children
                  items={children}
                  level={level + 1}
                  activeSlug={activeSlug}
                />
              </div>
            </div>
          )}
        </React.Fragment>
      )
    }
  )
}
