import React from "react"
import SEO from "../seo"
import * as styles from "./Layout.module.scss"

export default function Layout({
  children,
  title,
  description,
  className = "",
}) {
  return (
    <div className={`${styles.layout} ${className}`}>
      <SEO title={title} description={description} lang="en-US" />
      {children}
    </div>
  )
}
