const STRING_INDEX = {
  "getting-started": "Getting Started",
  guides: "Guides",
  concepts: "Concepts",
  recipes: "Recipes",
  misc: "Misc",
  "field-types": "Types",
  graphs: "Graph",
  api: "API Triggers",
  docs: "Docs",
  projects: "Projects",
  dashboard: "Dashboard",
  marketplace: "Marketplace",
  "webhook-providers": "Webhook Providers",
  billing: "Billing",
  "fe-builder": "Frontend Builder",
  pages: "Pages",
  components: "Components",
  library: "Library",
  ui: "UI",
  data: "Data",
  datasources: "Datasources",
  workflows: "Workflows",
  tables: "Tables",
  endpoints: "Endpoints",
  fields: "Fields",
  fieldsets: "Fieldsets",
  cms: "CMS",
  "table-types": "Types",
  "fieldset-types": "Types",
  "endpoint-types": "Trigger Types",
  "scheduled-triggers": "Scheduled Triggers",
  "integration-triggers": "Integration Triggers",
  "database-triggers": "Database Triggers",
  inputs: "Inputs",
  layout: "Layout",
  overlays: "Overlays",
  custom: "Custom",
  settings: "Settings",
  webhooks: "Webhooks",
  profile: "Profile",
  "webhook-types": "Webhook Types",
  "self-hosted": "Self Hosted",
}

export default STRING_INDEX
